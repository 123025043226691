import {
  InlineValue,
  Link,
  resolveLocale,
  useCategories,
  useContent,
  useContents,
  usePageContent
} from "@appiodev/xcore-client/xcore-ui";
import { Box, Container, Icon, Link as UILink, Stack, Typography } from "@xcorejs/ui";
import DropIcon8 from "components/icons/8/drop.svg";
import { NavLinkStyle } from "components/Layout/Header/NavItem/NavItemLink";
import SmartLink from "components/SmartLink";
import VideoCarousel from "components/VideoCarousel";
import VideoImageCarousel from "components/VideoImageCarousel";
import VimeoButton from "design-system/appio/buttons/Vimeo";
import YoutubeButton from "design-system/appio/buttons/Youtube";
import RobeHeading from "design-system/robe/RobeHeading";
import { darken } from "polished";
import { FC, Fragment, useMemo } from "react";
import { resolveLocaleField } from "utils/useLocalizedRelation";
import { useLayout } from "xcore";
import { Homepage, HpSlider, News, Video } from "xcore/types";

import NewsSection from "./NewsSection";

const HomepageTemplate: FC = () => {
  const [{ values }, { value, cms, stringify }] = usePageContent<Homepage>();
  const [hpSlider] = useContent<[HpSlider]>();

  const [news] = useContents<[News]>();
  const [newsCategories] = useCategories();
  const { general, header } = useLayout();

  const videos = useMemo(() =>
    resolveLocaleField("hpVideoSlider", value(hpSlider.values), [] as Video[], cms.locales.content),
  [cms.locales.content, hpSlider.values, value]);

  const nwl = useMemo(() => resolveLocale(cms.locales.content)({
    "en": values.nwlLinkEn,
    "de": values.nwlLinkDe,
    "ru": values.nwlLinkRu,
    "en-EN": values.nwlLinkEnEN,
    "fr": values.nwlLinkFr
  }) ?? undefined, [cms.locales.content, values.nwlLinkEn, values.nwlLinkDe, values.nwlLinkRu, values.nwlLinkEnEN, values.nwlLinkFr]);

  const slides = useMemo(() => [
    ...resolveLocaleField("heroSlider", value(hpSlider.values), [], cms.locales.content)
  ], [hpSlider.values, cms.locales.content, value]);

  return (
    <>
      <VideoImageCarousel
        slides={slides}
        aspectRatio={9 / 23}
        autoPlay={parseInt(value(hpSlider.values.slideDuration)!) >= 100}
        autoPlaySpeed={parseInt(value(hpSlider.values.slideDuration)!)}
        showBullets={slides?.length > 1}
        showNavArrows={slides?.length > 1}
        waitForWindowObjectBeforeRender // in order to pick correct video ratio based on user screen width
      />

      <Container flexDirection="column" pt={{ _: "2rem", md: "10rem" }} pb={{ _: "3rem", sm: "10rem" }}>
        <Box mx="-1.6rem" display={{ _: "block", md: "none" }} mt="3rem">
          <RobeHeading mb={{ _: "3rem", sm: "4rem" }}>
            <InlineValue value={values.specialHPmobileMenuHeading} />
          </RobeHeading>

          <Box borderTop="1px solid #dee1e5">
            {values.specialHPmobileMenu?.map((link, index) => (
              <Fragment key={index}>
                {stringify(link.menuItemExternalUrl)!.length > 0 && (
                  <UILink
                    key={index}
                    href={stringify(link.menuItemExternalUrl)}
                    type="simple"
                    {...NavLinkStyle}
                    px="1.6rem"
                    fontWeight={600}
                    minHeight="5rem"
                    width="100%"
                    color="darkGray"
                    justifyContent="space-between"
                    borderBottom="1px solid #dee1e5"
                    borderColor="#dee1e5"
                  >
                    {stringify(link.menuItemLabel)}
                    <Icon svg={<DropIcon8 />} fill="gray" transform="rotate(-90deg)" />
                  </UILink>
                )}
                {value(link).menuItemContentRelation && (
                  <Link
                    key={index}
                    {...NavLinkStyle}
                    content={value(link).menuItemContentRelation!}
                    px="1.6rem"
                    fontWeight={600}
                    minHeight="5rem"
                    width="100%"
                    color="darkGray"
                    justifyContent="space-between"
                    borderBottom="1px solid #dee1e5"
                    borderColor="#dee1e5"
                  >
                    {stringify(link.menuItemLabel)}
                    <Icon svg={<DropIcon8 />} fill="gray" transform="rotate(-90deg)" />
                  </Link>
                )}
              </Fragment>
            ))}
          </Box>
        </Box>

        <RobeHeading mb={{ _: "3rem", sm: "4rem" }} mt={{ _: "6rem", md: "0" }}>
          <InlineValue value={values.videos} />
        </RobeHeading>

        {videos && (
          <Box mb={{ _: "1rem", sm: "6rem" }}>
            <VideoCarousel videos={videos} />
          </Box>
        )}

        <Stack justify="center" direction="column" align="center" gap="3rem">
          <Typography variant="lead" textAlign="center" display={{ _: "none", sm: "block" }}>
            <InlineValue value={values.videosDescription} />
          </Typography>

          <Stack direction={{ _: "column", xs: "row" }} gap="3rem">
            <VimeoButton
              as="a"
              href="https://vimeo.com/robelighting"
              target="_blank"
              boxShadow={{ _: "none", sm: "0 3.5rem 2.5rem -2.5rem rgba(26, 183, 234, 1)" }}
              _hover={{ bg: darken(0.025, "#1ab7ea"), boxShadow: "0 4.5rem 2.5rem -2.5rem rgba(26,183,234,1)" }}
              _focus={{
                bg: darken(0.025, "#1ab7ea"),
                outline: "none",
                boxShadow: "0 4.5rem 2.5rem -2.5rem rgba(26,183,234,1), inset 0 0 0 .2rem #15a0cf"
              }}
            >
              Vimeo
            </VimeoButton>
            <YoutubeButton
              as="a"
              href="https://www.youtube.com/user/RobeLightingTube"
              target="_blank"
              boxShadow="0 3.5rem 2.5rem -2.5rem rgba(255, 0, 0, 1)"
              _hover={{ boxShadow: "0 4.5rem 2.5rem -2.5rem rgba(255,0,0,1)" }}
            >
              Youtube
            </YoutubeButton>
          </Stack>
        </Stack>
      </Container>

      <NewsSection
        title={<InlineValue value={values.news} />}
        news={news}
        categories={newsCategories}
        buttonText={stringify(general.values.btnExploreMore)}
        buttonLink={{ content: header.nav.stories }}
      />

      <Box pt={{ _: "9rem", sm: "10rem" }} pb={{ _: "6rem", sm: "10rem" }}>
        <Container flexDirection="column">
          <Stack direction="column" gap={{ _: "3rem", sm: "4rem" }} align="center">
            <RobeHeading><InlineValue value={values.newsletter} /></RobeHeading>

            <Box mt={{ _: "-2rem", sm: 0 }}>
              <Typography variant="lead" maxWidth="63rem" mx="auto" textAlign="center">
                <InlineValue value={values.newsletterDescription} />
              </Typography>
            </Box>

            {nwl &&
              <SmartLink smartLink={value(nwl)!} asButton />}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default HomepageTemplate;
